html, body {
    margin: 0;
    padding: 0;
}

* {
    font-family: GothamRoundedBook, sans-serif;
    box-sizing: border-box;
}

@font-face {
    font-family: GothamRounded;
    src: url("fonts/GothamRoundedMedium.eot");
    src: local('Gotham Rounded'), local('GothamRounded'),
    url("./fonts/GothamRoundedMedium.eot#iefix") format('embedded-opentype'),
    url("./fonts/GothamRoundedMedium.woff2") format('woff2'),
    url("./fonts/GothamRoundedMedium.woff") format('woff');
}

@font-face {
    font-family: GothamRoundedBook;
    src: url("fonts/GothamRoundedBook.eot");
    src: local('Gotham Rounded'), local('GothamRounded'),
        url("./fonts/GothamRoundedBook.eot#iefix") format('embedded-opentype'),
        url("./fonts/GothamRoundedBook.woff2") format('woff2'),
        url("./fonts/GothamRoundedBook.woff") format('woff');
}

@font-face {
    font-family: GothamRoundedBold;
    src: url("fonts/GothamRoundedBold.eot");
    src: url("fonts/GothamRoundedBold.eot#iefix") format('embedded-opentype'),
        url("./fonts/GothamRoundedBold.eot#iefix") format('embedded-opentype'),
        url("./fonts/GothamRoundedBold.woff2") format('woff2'),
        url("./fonts/GothamRoundedBold.woff") format('woff');
}
